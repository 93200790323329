import React from 'react'
import { Spring } from 'react-spring/renderprops'

import { IndentDescription } from '../components/IndentDescription'
import { Layout } from '../components/layout'
import Seo from '../components/seo'
import { withBreakpoints } from '../hoc/withBreakpoints'
import { Container } from '../components/Container'
import { HeroBanner } from '../components/HeroBanner'
import { Team } from '../components/Team'
import { ContactSection } from '../components/ContactSection'
import { VisibilitySensorOnce } from '../components/VisibilitySensorOnce'

import * as styles from './o-nas-page.module.scss'
import { graphql } from 'gatsby'
import { Image } from 'cloudinary-react'

const AboutUsPage = ({
  isMobile,
  data: {
    markdownRemark: { frontmatter },
  },
}) => (
  <Layout>
    <Seo
      title="O nas"
      description={frontmatter.seoDescription}
      keywords={frontmatter.seoKeywords}
      image={frontmatter.mainImage}
    />
    <HeroBanner darkNextSection header={frontmatter.title}>
      {frontmatter.subtitle}
    </HeroBanner>
    <div>
      <div className={styles.descriptionDark}>
        <IndentDescription>
          {frontmatter.darkSectionParagraphs.map((content, i) => (
            <p key={i}>{content}</p>
          ))}
        </IndentDescription>
      </div>
      <div className={styles.quoteSpace}>
        <Container className={styles.quoteSpace__container}>
          <div className={styles.quoteWrapper}>
            <VisibilitySensorOnce partialVisibility minTopValue={150}>
              {({ isVisible }) => (
                <Spring
                  to={{ opacity: isVisible ? 1 : 0, translate: isVisible ? (isMobile ? 0 : 50) : isMobile ? 30 : 80 }}
                >
                  {({ opacity, translate }) => (
                    <div
                      style={{ opacity, transform: `translate(${translate}%, ${isMobile ? 0 : -50}%)` }}
                      className={styles.quotePhoto}
                    >
                      <Image
                        alt="Wutektura"
                        src={frontmatter.mainImage}
                        responsive
                        dpr="auto"
                        aspect_ratio="1.5"
                        width="auto"
                        crop="fill"
                      />
                    </div>
                  )}
                </Spring>
              )}
            </VisibilitySensorOnce>
            <VisibilitySensorOnce partialVisibility minTopValue={150}>
              {({ isVisible }) => (
                <Spring
                  to={{
                    opacity: isVisible ? 1 : 0,
                    translate: isVisible ? (isMobile ? 0 : -50) : isMobile ? 0 : -90,
                  }}
                >
                  {({ opacity, translate }) => (
                    <div style={{ opacity, transform: `translateX(${translate}%)` }} className={styles.quote}>
                      {frontmatter.mainImageQuote}
                    </div>
                  )}
                </Spring>
              )}
            </VisibilitySensorOnce>
          </div>
        </Container>
      </div>

      <Team teamMembers={frontmatter.teamMembers} />
      <IndentDescription smallSpacing>
        {frontmatter.lightSectionParagraphs.map((content, i) => (
          <p key={i}>{content}</p>
        ))}
      </IndentDescription>
      <ContactSection />
    </div>
  </Layout>
)

export default withBreakpoints(
  {
    mobile: {
      isMobile: true,
    },
    fallback: {
      isMobile: false,
    },
  },
  AboutUsPage
)

export const aboutUsPageQuery = graphql`
  query AboutUsPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        seoDescription
        seoKeywords
        title
        subtitle
        darkSectionParagraphs
        mainImage
        mainImageQuote
        lightSectionParagraphs
        teamMembers {
          fullName
          role
          image
          about
        }
      }
    }
  }
`
