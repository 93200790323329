import React from 'react'
import { Spring } from 'react-spring/renderprops'

import { Container } from '../Container'
import { VisibilitySensorOnce } from '../VisibilitySensorOnce'

import * as styles from './Team.module.scss'
import { Image } from '../Image'

export const Team = ({ teamMembers }) => (
  <Container>
    <div className={styles.teamWrapper}>
      {teamMembers.map(({ fullName, role, image, about }) => (
        <VisibilitySensorOnce partialVisibility minTopValue={60} key={fullName}>
          {({ isVisible }) => (
            <Spring to={{ opacity: isVisible ? 1 : 0, translate: isVisible ? 0 : 50 }}>
              {({ opacity, translate }) => (
                <div style={{ opacity, transform: `translateY(${translate}px)` }} className={styles.teamMember}>
                  <Image alt={fullName} src={image} responsive dpr="auto" width="auto" crop="fill" acpect_ratio="1" />
                  <h2 className={styles.teamMember__fullName}>{fullName}</h2>
                  <div className={styles.teamMember__role}>{role}</div>
                  <p className={styles.teamMember__description}>{about}</p>
                </div>
              )}
            </Spring>
          )}
        </VisibilitySensorOnce>
      ))}
    </div>
  </Container>
)
