// extracted by mini-css-extract-plugin
export var underlinedHeader = "UnderlinedHeader-module--underlined-header--3uv7_";
export var underlinedHeader__text = "UnderlinedHeader-module--underlined-header__text--MUziW";
export var underlinedHeader__line = "UnderlinedHeader-module--underlined-header__line--1527k";
export var underlinedHeader__lineBottom = "UnderlinedHeader-module--underlined-header__line--bottom--3Ursf";
export var underlinedHeader__lineTop = "UnderlinedHeader-module--underlined-header__line--top--vuPB0";
export var underlinedHeader__lineCenter = "UnderlinedHeader-module--underlined-header__line--center--1Ry_B";
export var underlinedHeader__lineLeft = "UnderlinedHeader-module--underlined-header__line--left--2I2BL";
export var underlinedHeaderDark = "UnderlinedHeader-module--underlined-header--dark--XbF7l";
export var underlinedHeaderLight = "UnderlinedHeader-module--underlined-header--light--13MQa";
export var underlinedHeaderCentered = "UnderlinedHeader-module--underlined-header--centered--2LvfP";