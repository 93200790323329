import React from 'react'
import PropTypes from 'prop-types'
import cx from 'clsx'
import { Spring } from 'react-spring/renderprops'
import { VisibilitySensorOnce } from '../VisibilitySensorOnce'

import { Line } from '../Line'

import * as styles from './UnderlinedHeader.module.scss'

const LineComponent = ({ placement, isVisible }) => (
  <span
    className={cx([
      styles.underlinedHeader__line,
      placement.includes('center') ? styles.underlinedHeader__lineCenter : styles.underlinedHeader__lineLeft,
      placement.includes('top') ? styles.underlinedHeader__lineTop : styles.underlinedHeader__lineBottom,
    ])}
  >
    <Line size="big" reveal={isVisible} />
  </span>
)

const UnderlinedHeader = ({ underlinePlacement, theme, children, centered, className }) => (
  <VisibilitySensorOnce partialVisibility minTopValue={140}>
    {({ isVisible }) => (
      <h3
        className={cx([
          styles.underlinedHeader,
          theme === 'dark' ? styles.underlinedHeaderDark : styles.underlinedHeaderLight,
          centered && styles.underlinedHeaderCentered,
          className,
        ])}
      >
        {underlinePlacement.includes('top') && <LineComponent isVisible={isVisible} placement={underlinePlacement} />}
        <Spring to={{ opacity: isVisible ? 1 : 0, translate: isVisible ? 0 : 40 }}>
          {({ opacity, translate }) => (
            <span
              style={{ opacity, transform: `translateY(${translate}px)` }}
              className={styles.underlinedHeader__text}
            >
              {children}
            </span>
          )}
        </Spring>
        {underlinePlacement.includes('bottom') && (
          <LineComponent isVisible={isVisible} placement={underlinePlacement} />
        )}
      </h3>
    )}
  </VisibilitySensorOnce>
)

UnderlinedHeader.propTypes = {
  underlinePlacement: PropTypes.oneOf(['top-left', 'bottom-left', 'bottom-center']),
  theme: PropTypes.oneOf(['dark', 'light']),
  className: PropTypes.string,
  centered: PropTypes.bool,
}

export { UnderlinedHeader }
