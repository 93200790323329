import React from 'react'
import { Spring } from 'react-spring/renderprops'
import cx from 'clsx'

import { VisibilitySensorOnce } from '../VisibilitySensorOnce'
import { Container } from '../Container'
import * as styles from './IndentDescription.module.scss'

const IndentDescription = ({ children, smallSpacing }) => (
  <Container className={cx(styles.indentDescription, smallSpacing && styles.indentDescriptionSmallSpacing)}>
    <VisibilitySensorOnce partialVisibility minTopValue={100}>
      {({ isVisible }) => (
        <Spring to={{ opacity: isVisible ? 1 : 0 }}>
          {({ opacity }) => (
            <div className={styles.indentDescriptionContainer} style={{ opacity }}>
              {children}
            </div>
          )}
        </Spring>
      )}
    </VisibilitySensorOnce>
  </Container>
)

export { IndentDescription }
