import React from 'react'
import { Spring } from 'react-spring/renderprops'

import { VisibilitySensorOnce } from '../VisibilitySensorOnce'
import { Button } from '../Button'
import { UnderlinedHeader } from '../UnderlinedHeader'

import * as styles from './ContactSection.module.scss'
import { graphql, useStaticQuery } from 'gatsby'
import { normalizePhoneNumber, prettifyPhoneNumber } from '../../utils'

const ContactSection = () => {
  const {
    markdownRemark: { frontmatter },
  } = useStaticQuery(
    graphql`
      query {
        markdownRemark(frontmatter: { templateKey: { eq: "kontakt-page" } }) {
          frontmatter {
            email
            phoneNumbers {
              phoneNumber
            }
          }
        }
      }
    `
  )

  return (
    <div className={styles.contact}>
      <UnderlinedHeader underlinePlacement="bottom-center" theme="dark">
        Skontaktuj się z nami
      </UnderlinedHeader>
      <VisibilitySensorOnce>
        {({ isVisible }) => (
          <Spring to={{ opacity: isVisible ? 1 : 0, scale: isVisible ? 1 : 0.8 }}>
            {({ opacity, scale }) => (
              <a
                style={{ opacity, transform: `scale(${scale})` }}
                className={styles.phoneNumber}
                href={`tel:${normalizePhoneNumber(frontmatter.phoneNumbers[0].phoneNumber)}`}
              >
                {prettifyPhoneNumber(frontmatter.phoneNumbers[0].phoneNumber)}
              </a>
            )}
          </Spring>
        )}
      </VisibilitySensorOnce>
      <VisibilitySensorOnce>
        {({ isVisible }) => (
          <Spring to={{ opacity: isVisible ? 1 : 0, scale: isVisible ? 1 : 0.8 }}>
            {({ opacity, scale }) => (
              <a
                style={{ opacity, transform: `scale(${scale})` }}
                className={styles.email}
                href={`mailto:${frontmatter.email}`}
              >
                {frontmatter.email}
              </a>
            )}
          </Spring>
        )}
      </VisibilitySensorOnce>
      <Button href="/kontakt">Napisz do nas</Button>
    </div>
  )
}

export { ContactSection }
